<template>
  <div
    v-on-click-outside="() => (showMenu = false)"
    class="relative"
    @click.stop
  >
    <icon-button
      v-if="showReload"
      :icon="IconReload"
      data-cy="reload-button"
      @click="showMenu = !showMenu"
    />
    <primary-button v-else class="h-10 z-10" @click="showMenu = !showMenu">
      <span class="mr-6">
        {{ $t("COMMON.RUN_SERVICE") }}
      </span>
    </primary-button>
    <div
      v-if="!showReload"
      data-cy="models-dropdown"
      class="absolute cursor-pointer flex items-center justify-center right-0 top-0 w-10 h-10 rounded-r transform transition-all"
      :class="{
        'bg-blue-800': showMenu
      }"
      @click="showMenu = !showMenu"
    >
      <icon-base
        class="transform translate-y-1 translate-x-0.5 text-white"
        :icon="IconArrowUp"
      />
    </div>
    <ul
      class="transition-all absolute right-0 bg-white rounded shadow-lg w-80 p-2 max-h-60 overflow-auto"
      :class="showMenu ? 'top-11 z-40' : 'top-9 opacity-0 -z-1'"
      data-cy="run-dropdown-menu"
    >
      <action-menu-item
        v-for="item in items"
        :key="item.label"
        :class="{ 'bg-blue-50': selectedModels?.includes(item.model) }"
        :data-cy="item.model"
        class="my-1"
        @click="emit('item:selected', item)"
      >
        <div class="flex items-center justify-between">
          <span>{{ item.label }}</span>
          <icon-base
            v-if="selectedModels?.includes(item.model)"
            :icon="IconCheck"
            :icon-color="UTIL_COLORS.PRIMARY"
          />
        </div>
      </action-menu-item>
    </ul>
  </div>
</template>
<script setup lang="ts">
import IconArrowUp from "@/components/icons/IconArrowUp.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import ActionMenuItem from "@/components/ui/ActionMenuItem.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconButton from "@/components/ui/buttons/IconButton.vue";
import IconReload from "@/components/icons/IconReload.vue";

import { UTIL_COLORS } from "@/helpers/constants";
import { ref } from "vue";

import type { MultiServiceRunMenuItem } from "@/models/common";

interface Props {
  items: MultiServiceRunMenuItem[];
  selectedModels?: MultiServiceRunMenuItem["model"][];
  showReload?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  "item:selected": [MultiServiceRunMenuItem];
}>();
const showMenu = ref(false);
</script>
